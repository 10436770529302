import React, {FC, useEffect, ReactNode, useState} from 'react';
import ReactDOM from 'react-dom';

import Close from '../../assets/icons/CloseIcon.svg';

import {ModalWrapper, ModalContext, CloseIcon} from './styles';
import useScreenSizeHook from '../../hooks/useScreenSizeHook/useScreenSizeHook';

interface ModalProps {
  onClose: () => void;
  open: boolean;
  id: string;
  children?: ReactNode;
  width?: string;
  height?: string;
  maxWight?: string;
  maxHeight?: string;
  withEffect?: boolean;
}

const Modal: FC<ModalProps> = ({
  children, open, onClose, id, maxWight, maxHeight, width, height, withEffect = false
}) => {
  const {screenType} = useScreenSizeHook();

  const [isVisible, setIsVisible] = useState<boolean>(open);

  const handleOutsideClick = (event: MouseEvent) => {
    const modalId = document.getElementById(id);
    if (modalId && !modalId.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isVisible, onClose]);

  useEffect(() => {
    setIsVisible(open);

    if (open) {
      document.body.classList.add('modal-open');
      document.body.style.overflow = 'hidden';
    } else {
      document.body.classList.remove('modal-open');
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  return ReactDOM.createPortal(
    <ModalWrapper isVisible={isVisible}>
      <ModalContext
        id={id} className="white-color"
        maxWeight={maxWight} maxHeight={maxHeight} width={width} height={height}
      >
        <CloseIcon
          src={Close} alt="" title="" aria-label="" screenType={screenType}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
        {children}
      </ModalContext>
    </ModalWrapper>,
    document.getElementById('modal-root')!
  );
};

export default Modal;
