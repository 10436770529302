import styled, {keyframes} from 'styled-components';

import {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';
import Arrow from './Arrow';

const ButtonVisible = keyframes`
  0% {
    opacity: 0;
    bottom: 0;
  }
  100% {
    opacity: 1;
    bottom: 16%;
  }
`;

const ButtonHidden = keyframes`
  0% {
    opacity: 1;
    bottom: 16%;
  }
  100% {
    opacity: 0;
    bottom: 0;
  }
`;

export const Wrapper = styled.button<{ screenType: SCREEN_TYPES, activeAnimation: boolean }>`
  position: fixed;
  bottom: 0;
  right: 4.7%;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  
  width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 4.3 : 12}vw;
  height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 4.3 : 12}vw;

  clip-path: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ?
    'polygon(0.5vw 0%, calc(100% - 0.5vw) 0%, 100% 0.5vw, 100% calc(100% - 0.5vw), calc(100% - 0.5vw) 100%, 0.5vw 100%, 0% calc(100% - 0.5vw), 0% 0.5vw)' :
    'polygon(1.5vw 0%, calc(100% - 1.5vw) 0%, 100% 1.5vw, 100% calc(100% - 1.5vw), calc(100% - 1.5vw) 100%, 1.5vw 100%, 0% calc(100% - 1.5vw), 0% 1.5vw)'};

  background-color: #7E2EF4;
  
  padding: 0;
  margin: 0;
  outline: none;
  border: none;

  box-sizing: border-box;

  cursor: pointer;
  
  animation: ${({activeAnimation}) => activeAnimation ? ButtonVisible : ButtonHidden} 0.2s linear forwards;

  transition: all 0.15s linear;

  &:hover {
    transition: all 0.15s linear;

    ${({ screenType }) => screenType === SCREEN_TYPES.DESKTOP && `
      background-color: #00EFA0;
      color: #00883E;
    `}
  }
`;

export const ArrowIcon = styled(Arrow)<{screenType: SCREEN_TYPES }>`
  width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 1.7 : 4}vw;
  height: auto;
  
  fill: #FFFFFF;

  transition: all 0.15s linear;
  
  ${Wrapper}:hover & {
    transition: all 0.15s linear;

    ${({ screenType }) => screenType === SCREEN_TYPES.DESKTOP && `
      fill: #00883E;
    `}
  }
`