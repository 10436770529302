import React, {useContext} from 'react';

import useScreenSizeHook, {SCREEN_TYPES} from '../../../hooks/useScreenSizeHook/useScreenSizeHook';
import CONTEXT from '../../../context';

import ASSETS from '../../../assets/images/constants';

import {ClipButton, ClipButtonWrapper, BannerWrapper} from '../styles';
import {Title3D, Text3D} from './styles';

interface IConfirmationModal {
  onClose: () => void;
}

const ListingBFT = ({onClose}: IConfirmationModal) => {
  const {screenType} = useScreenSizeHook();
  const {getText} = useContext(CONTEXT.GLOBAL.LANGUAGE);

  const clickHandler = () => {
    window.open('https://economy.bossfighters.game/register', '_blank');
    onClose();
  };

  return (
    <BannerWrapper
      url={screenType === SCREEN_TYPES.DESKTOP ?
        ASSETS.components.modal.desktop.ListingBFTBackground :
        ASSETS.components.modal.mobile.ListingBFTBackgroundMob}
      onClick={clickHandler}
      style={{justifyContent: screenType === SCREEN_TYPES.DESKTOP ? 'space-between' : 'flex-start' }}
    >
      <div className="flex-column">
        <Title3D
          screenType={screenType}
          style={{
            margin: screenType === SCREEN_TYPES.DESKTOP ? '1vw 0 -2vw 0' : '8vw 0 -1.5vw 0',
          }}
          className="font-w-900 text-uppercase"
        >
          $BFTOKEN {getText('banner.listing.listingIs')}
        </Title3D>
        <Title3D
          screenType={screenType}
          className="font-w-900 text-uppercase"
        >
          {getText('banner.listing.almostHere')}!
        </Title3D>
        {screenType === SCREEN_TYPES.DESKTOP ? (
          <Text3D
            screenType={screenType}
            className="font-w-900"
          >
            {`${getText('banner.listing.signUpNow')} & ${getText('banner.listing.beTheFirst')}!`}
          </Text3D>
        ) : (
          <div className="flex-column font-w-900" style={{margin: '1vw 0 8vw 0'}}>
            <Text3D screenType={screenType}>
              {getText('banner.listing.signUpNow')}
            </Text3D>
            <Text3D screenType={screenType}>
              & {getText('banner.listing.beTheFirst')}!
            </Text3D>
          </div>
        )}
      </div>

      <ClipButtonWrapper
        className={`font-w-700 white-color text-uppercase ${screenType === SCREEN_TYPES.DESKTOP ? 'text-xl' : 'text-play-demo-mob'}`}
        screenType={screenType}
      >
        <ClipButton screenType={screenType}>
          {getText('header.SignUp')}
        </ClipButton>
      </ClipButtonWrapper>
    </BannerWrapper>
  );
};

export default ListingBFT;