export const PRODUCTION = (process.env.REACT_APP_PRODUCTION || 'false').toLowerCase() === 'true';
export const ASSETS_URL_PREFIX = process.env.REACT_APP_ASSETS_URL_PREFIX || 'https://dev-hub-ui-content.s3.eu-central-1.amazonaws.com/ui';
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '6LeTjekhAAAAAPAATwsADP0By0DK3An2Xe27BSpA';

const getIntEnv = (env: string, defaultValue: number): number => {
  try {
    const result = parseInt(env, 10);
    return isNaN(result) ? defaultValue : result;
  } catch (e) {
    return defaultValue;
  }
};

export const LISTING_BFT_TARGET_TIME = getIntEnv(process.env.LISTING_BFT_TARGET_TIME ?? '', 17468739430000000);
export const WELCOME_PACK_TARGET_TIME = getIntEnv(process.env.WELCOME_PACK_TARGET_TIME ?? '', 17468739430000000);
