import React, {useContext} from 'react';

import useScreenSizeHook, {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';
import CONTEXT from '../../context';

import ASSETS from '../../assets/images/constants';

import {SocialMedia, Wrapper, WrapperHref, WrapperLi, WrapperNav, WrapperUl} from './styles';

const Footer = () => {
  const {screenType} = useScreenSizeHook();
  const {getText} = useContext(CONTEXT.GLOBAL.LANGUAGE);

  return (
    <Wrapper screenType={screenType}
      url={screenType === SCREEN_TYPES.DESKTOP ? ASSETS.components.footer.desktop.FooterBackground :
        ASSETS.components.footer.mobile.FooterBackground}>
      <WrapperNav>
        <WrapperUl screenType={screenType}>
          <WrapperLi>
            <WrapperHref href='https://twitter.com/BossFightersX' target='_blank'
              width={40} className={screenType === SCREEN_TYPES.DESKTOP ? 'scaleOnHover' : ''}
              title={getText('footer.Twitter')}
              aria-label={getText('footer.Twitter')}
            >
              <SocialMedia src={ASSETS.components.socialMedia.desktop.TwitterIcon}
                title={getText('footer.Twitter')}
                aria-label={getText('footer.Twitter')}
                alt={getText('footer.Twitter')}
              />
            </WrapperHref>
          </WrapperLi>
          <WrapperLi>
            <WrapperHref href='https://t.me/PlayBossFighters_ann' target='_blank'
              width={43} className={screenType === SCREEN_TYPES.DESKTOP ? 'scaleOnHover' : ''}
              title={getText('footer.Telegram')}
              aria-label={getText('footer.Telegram')}
            >
              <SocialMedia src={ASSETS.components.socialMedia.desktop.TelegramIcon}
                title={getText('footer.Telegram')}
                aria-label={getText('footer.Telegram')}
                alt={getText('footer.Telegram')}
              />
            </WrapperHref>
          </WrapperLi>
          <WrapperLi>
            <WrapperHref href='https://www.youtube.com/@playbossfighters' target='_blank'
              width={47} className={screenType === SCREEN_TYPES.DESKTOP ? 'scaleOnHover' : ''}
              title={getText('footer.YouTube')}
              aria-label={getText('footer.YouTube')}
            >
              <SocialMedia src={ASSETS.components.socialMedia.desktop.YoutubeIcon}
                title={getText('footer.YouTube')}
                aria-label={getText('footer.YouTube')}
                alt={getText('footer.YouTube')}
              />
            </WrapperHref>
          </WrapperLi>
          <WrapperLi>
            <WrapperHref href='https://discord.com/invite/playbossfighters' target='_blank'
              width={46} className={screenType === SCREEN_TYPES.DESKTOP ? 'scaleOnHover' : ''}
              title={getText('footer.Discord')}
              aria-label={getText('footer.Discord')}
            >
              <SocialMedia src={ASSETS.components.socialMedia.desktop.DiscordIcon}
                title={getText('footer.Discord')}
                aria-label={getText('footer.Discord')}
                alt={getText('footer.Discord')}
              />
            </WrapperHref>
          </WrapperLi>
          <WrapperLi>
            <WrapperHref href='https://www.tiktok.com/@playbossfighters' target='_blank'
              width={33} className={screenType === SCREEN_TYPES.DESKTOP ? 'scaleOnHover' : ''}
              title={getText('footer.Tiktok')}
              aria-label={getText('footer.Tiktok')}
            >
              <SocialMedia src={ASSETS.components.socialMedia.desktop.TiktokIcon}
                title={getText('footer.Tiktok')}
                aria-label={getText('footer.Tiktok')}
                alt={getText('footer.Tiktok')}
              />
            </WrapperHref>
          </WrapperLi>
        </WrapperUl>
      </WrapperNav>
    </Wrapper>
  );
};

export default Footer;