import React, {useState, useEffect} from 'react';

import useScreenSizeHook from '../../hooks/useScreenSizeHook/useScreenSizeHook';

import {ArrowIcon, Wrapper} from './styles';

interface IScrollButton {
  activeAnimation: boolean;
}

const ScrollButton = ({activeAnimation}: IScrollButton) => {
  const {screenType} = useScreenSizeHook();

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 1000)
  }, [])

  return (
    <Wrapper
      screenType={screenType}
      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
      style={{visibility: loaded ? 'visible' : 'hidden'}}
      activeAnimation={activeAnimation}
    >
      <ArrowIcon screenType={screenType}/>
    </Wrapper>
  );
};

export default ScrollButton;