import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import DefaultLayout from './components/layout/DefaultLayout';

import Loader from './components/Loader/Loader';
import ScrollToTop from './utils/ScrollToTop/ScrollToTop';

import {LanguageProvider} from './context/languageContext/languageProvider';
import {ModalProvider} from './context/modalContext/modalProvider';

import './scss/style.scss';
import './index.css';

const loading = (
  <div className="lazy-loader-container">
    <Loader size={64} borderWidth={6}/>
  </div>
);

export default function App() {
  return (
    <LanguageProvider>
      <ModalProvider>
        <Router>
          <ScrollToTop/>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="*" element={<DefaultLayout/>}/>
            </Routes>
          </Suspense>
        </Router>
      </ModalProvider>
    </LanguageProvider>
  );
}
