import {createContext, Dispatch, SetStateAction} from 'react';

export const ModalContext = createContext<{
  listingModalVisible: boolean,
  setListingModalVisible: Dispatch<SetStateAction<boolean>>,
  isListingTimerFinished: boolean,
  setIsListingTimerFinished: Dispatch<SetStateAction<boolean>>,
  welcomeModalVisible: boolean,
  setWelcomeModalVisible: Dispatch<SetStateAction<boolean>>,
  isWelcomeTimerFinished: boolean,
  setIsWelcomeTimerFinished: Dispatch<SetStateAction<boolean>>,
}>({
  listingModalVisible: false,
  setListingModalVisible: () => {
  },
  isListingTimerFinished: false,
  setIsListingTimerFinished: () => {
  },
  welcomeModalVisible: false,
  setWelcomeModalVisible: () => {
  },
  isWelcomeTimerFinished: false,
  setIsWelcomeTimerFinished: () => {
  },
});