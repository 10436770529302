import styled from 'styled-components';
import {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

export const ModalWrapper = styled.div<{ isVisible: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.75);

    box-sizing: border-box;

    transition: all 0.15s linear;
    opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
    visibility: ${({isVisible}) => (isVisible ? 'visible' : 'hidden')};
    pointer-events: ${({isVisible}) => (isVisible ? 'auto' : 'none')};
`

export const ModalContext = styled.div<{ width?: string, height?: string, maxWeight?: string, maxHeight?: string }>`
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: ${({width = 'auto'}) => width};
    height: ${({height = 'auto'}) => height};
    max-width: ${({maxWeight = 'auto'}) => maxWeight};
    max-height: ${({maxHeight = 'auto'}) => maxHeight};

    padding: 0;
    margin: 0;
    
    box-sizing: border-box;
`

export const BannerWrapper = styled.div<{url: string}>`
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;

    background-image: url(${({url}) => url});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  
    cursor: pointer;
`

export const ClipButtonWrapper = styled.div<{screenType: SCREEN_TYPES}>`
    position: relative;

    width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 21 : 70}vw;
    height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 7 : 16.8}vw;

    clip-path: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 
    'polygon(1vw 0%, calc(100% - 1vw) 0%, 100% 1vw, 100% calc(100% - 1vw), calc(100% - 1vw) 100%, 1vw 100%, 0% calc(100% - 1vw), 0% 1vw)' :
    'polygon(2.5vw 0%, calc(100% - 2.5vw) 0%, 100% 2.5vw, 100% calc(100% - 2.5vw), calc(100% - 2.5vw) 100%, 2.5vw 100%, 0% calc(100% - 2.5vw), 0% 2.5vw)'};

    background: #FFFFFF;

    cursor: pointer;

    outline: none;
    border: none;
    padding: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 0.25 : 0.5}vw;
    margin: 0 0 4vw 0;

    box-sizing: border-box;
`;

export const ClipButton = styled.div<{screenType: SCREEN_TYPES}>`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    height: 100%;

    clip-path: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ?
    'polygon(1vw 0%, calc(100% - 1vw) 0%, 100% 1vw, 100% calc(100% - 1vw), calc(100% - 1vw) 100%, 1vw 100%, 0% calc(100% - 1vw), 0% 1vw)' :
    'polygon(2.5vw 0%, calc(100% - 2.5vw) 0%, 100% 2.5vw, 100% calc(100% - 2.5vw), calc(100% - 2.5vw) 100%, 2.5vw 100%, 0% calc(100% - 2.5vw), 0% 2.5vw)'};

    background: linear-gradient(0.59deg, #FFAA00 0.92%, #FC2BAA 85.72%);

    cursor: pointer;

    outline: none;
    border: none;
    padding: 0;
    margin: 0;

    box-sizing: border-box;
`;

export const CloseIcon = styled.img<{ screenType: SCREEN_TYPES }>`
  position: absolute;
  top: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 0.5 : 1.5}vw;
  right:${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 0.5 : 1.5}vw;
  z-index: 1;

  width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '2vw' : '6vw'};
  height: auto;
  
  cursor: pointer;
`

export const Title3D = styled.span<{ screenType: SCREEN_TYPES }>`
  color: #FFE150;
  font-size: ${({ screenType }) => (screenType === SCREEN_TYPES.DESKTOP ? '5vw' : '10vw')};
  transform: ${({ screenType }) => (screenType === SCREEN_TYPES.DESKTOP ? 'rotate(-2deg)' : 'rotate(-2deg)')};

  text-shadow:
    0 0 10px rgba(255, 225, 80, 0.8),  /* Свечение перед буквами */
    0 0 20px rgba(255, 225, 80, 0.5),  /* Дополнительное размытие */
    1px 1px 0 #000000,
    2px 2px 0 #000000,
    3px 3px 0 #000000,
    4px 4px 0 #000000,
    5px 5px 0 #000000,
    6px 6px 0 #000000,
    7px 7px 0 #000000,
    8px 8px 0 #000000,
    9px 9px 0 #000000,
    10px 10px 12px #000000;
`;

export const Text3D = styled.span<{ screenType: SCREEN_TYPES }>`
  color: #FFFFFF;
  font-size: ${({ screenType }) => (screenType === SCREEN_TYPES.DESKTOP ? '3.5vw' : '10vw')};
  transform: ${({ screenType }) => (screenType === SCREEN_TYPES.DESKTOP ? 'rotate(-2deg)' : 'rotate(-2deg)')};

  text-shadow:
    1px 1px 0 #000000,
    2px 2px 0 #000000,
    3px 3px 0 #000000,
    4px 4px 6px #000000;
`;