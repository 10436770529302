export type CharacterIndex = number;
export type ScrollTo = string;
export type Language = string;
export type LastModalOpenTime = string;

export type LocalStorageTypes =
    | CharacterIndex
    | ScrollTo
    | Language
    | LastModalOpenTime;

export enum LOCAL_STORAGE_NAMES {
    CHARACTER_INDEX = 'characterIndex',
    SCROLL_TO = 'scrollTo',
    LANGUAGE = 'language',
    LAST_MODAL_OPEN_TIME = 'lastModalOpenTime',
}
