const HEADER_LINKS: { [key: string]: string } = {
  MARKETPLACE: 'https://openloot.com/games/boss-fighters',
  TEAM: 'https://pixward-games.com/',
  SIGN_UP: 'https://economy.bossfighters.game/register',
  WIKI: 'https://wiki.bossfighters.game/',
  CH_WIKI: 'https://wiki-cn.bossfighters.game/',
  QUEST: 'https://quest.bossfighters.game/',
  AIRDROP: 'https://t.me/BOSS_Tapper_bot',
  ECONOMY: 'https://economy.bossfighters.game/',
  BFT: '/bftoken',
  NEWS: 'https://medium.com/@PlayBossFighters',
}

export default HEADER_LINKS;
