import React, {useContext, useEffect, useState} from 'react';

import CONTEXT from '../../context';
import useScreenSizeHook from '../../hooks/useScreenSizeHook/useScreenSizeHook';

import AppContent from '../AppContent';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import Modal from '../Modal/Modal';
import ListingBFT from '../Modal/ListingBFT/ListingBFT';
import WelcomePack from '../Modal/WelcomePack/WelcomePack';

import ScrollButton from '../ScrollButton/ScrollButton';

import {Wrapper} from './styles';

const DefaultLayout = () => {
  const {
    listingModalVisible, setListingModalVisible,
    welcomeModalVisible, setWelcomeModalVisible,
    isWelcomeTimerFinished, setIsWelcomeTimerFinished
  } = useContext(CONTEXT.GLOBAL.MODAL);

  const {screenType, width} = useScreenSizeHook();

  const [activeAnimation, setActiveAnimation] = useState<boolean>(false);

  const checkVerticalScroll = (): void => {
    const screenHeight = window.innerHeight;
    const scrollY = window.scrollY;

    if (scrollY >= screenHeight) {
      setActiveAnimation(true);
    } else {
      setActiveAnimation(false);
    }
  };

  useEffect(() => {
    checkVerticalScroll();

    window.addEventListener('scroll', checkVerticalScroll);
    return () => window.removeEventListener('scroll', checkVerticalScroll);
  }, [screenType, width]);

  return (
    <>
      <Wrapper>
        <Header/>
        <AppContent/>
        <Footer/>
        <ScrollButton activeAnimation={activeAnimation}/>
      </Wrapper>

      <Modal
        id="ListingBFTModal"
        open={listingModalVisible}
        onClose={() => setListingModalVisible(false)}
        width="85vw"
        height='85vh'
      >
        <ListingBFT
          onClose={() => setListingModalVisible(false)}
        />
      </Modal>

      {/*<Modal*/}
      {/*  id="WelcomePackModal"*/}
      {/*  open={welcomeModalVisible}*/}
      {/*  onClose={() => setWelcomeModalVisible(false)}*/}
      {/*  width="85vw"*/}
      {/*  height='85vh'*/}
      {/*>*/}
      {/*  <WelcomePack*/}
      {/*    onClose={() => setWelcomeModalVisible(false)}*/}
      {/*    isTimerFinished={isWelcomeTimerFinished}*/}
      {/*    setIsTimerFinished={setIsWelcomeTimerFinished}*/}
      {/*  />*/}
      {/*</Modal>*/}
    </>
  );
};
export default DefaultLayout;
