import styled from 'styled-components';
import {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

export const Wrapper = styled.footer<{ url: string, screenType: SCREEN_TYPES }>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;

  width: 100%;
  height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 6.5 : 21}vw;

  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-size: cover;

  pointer-events: none;
`

export const WrapperNav = styled.nav`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  width: 100%;
  height: 100%;
`

export const WrapperUl = styled.ul<{ screenType: SCREEN_TYPES }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 23 : 63}vw;
  height: 70%;

  margin: 0 ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 2 : 5.5}vw 0 0;
  padding: 0;

  list-style-type: none;
`

export const WrapperLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  margin: 0;
`

export const WrapperHref = styled.a<{ width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({width}) => width}%;
  height: auto;

  text-decoration: none;
  text-transform: uppercase;
  color: #FFFFFF;

  pointer-events: all;
`

export const SocialMedia = styled.img`
  width: 100%;
  height: 100%;
`