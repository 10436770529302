import React from 'react';

const Main = React.lazy(() => import('../pages/main'));
const PrivacyPolicy = React.lazy(() => import('../pages/privacyPolicy'));
const BFT = React.lazy(() => import('../pages/bft'));

export interface IRoute {
    path: string;
    exact: boolean;
    name: string;
    element: React.LazyExoticComponent<() => JSX.Element>;
}

export const publicRoutes: IRoute[] = [
  {path: '*', exact: true, name: 'Main', element: Main},
  {path: '/privacy-policy', exact: true, name: 'Main', element: PrivacyPolicy},
  {path: '/bftoken', exact: true, name: 'BFTOKEN', element: BFT},
];