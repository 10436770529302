import styled, {keyframes, css} from 'styled-components';
import {Link} from 'react-router-dom';
import {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';

const rotateIn = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(45deg);
  }
`;

const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOutRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const Wrapper = styled.header<{ url: string, screenType: SCREEN_TYPES }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 7.7 : 22.4}vw;

  background-image: url(${({url}) => url});
  background-repeat: no-repeat;
  background-size: cover;

  padding: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '0 5vw 0 3.5vw' : '5.8vw 5vw'};

  box-sizing: border-box;
`

export const WrapperLogo = styled.div<{ screenType: SCREEN_TYPES }>`
  display: flex;
  align-items: center;
  justify-content: center;

  align-self: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 'center' : 'flex-start'};

  width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 8 : 30.6}vw;

  margin-right: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 7 : 0}vw;
  margin-bottom: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 1 : 0}vw;
`

export const Logo = styled.img`
  width: 100%;
  height: 100%;
`

export const WrapperNav = styled.nav<{ screenType: SCREEN_TYPES }>`
  display: flex;
  align-items: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 'center' : 'flex-start'};

  width: 100%;
  height: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? '4.5vw' : 'auto'};

  max-width: 77.5vw;
`

export const WrapperUl = styled.ul<{ screenType: SCREEN_TYPES }>`
  display: flex;
  align-items: center;
  justify-content: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 'space-between' : 'center'};

  width: 100%;

  margin: 0;
  padding: 0;

  list-style-type: none;
`

export const WrapperLi = styled.li<{ marginBottom?: number }>`
  position: relative;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: ${({marginBottom = 0}) => marginBottom}vw;
`

export const WrapperHref = styled.a<{ disabled?: boolean }>`
  text-decoration: none;
  text-transform: uppercase;
  color: ${({disabled = false}) => disabled ? '#4539AF' : '#FFFFFF'};

  cursor: ${({disabled = false}) => disabled ? 'default' : 'pointer'};
`

export const WrapperLink = styled(Link)<{ disabled?: boolean }>`
  text-decoration: none;
  text-transform: uppercase;
  color: ${({disabled = false}) => disabled ? '#4539AF' : '#FFFFFF'};

  cursor: ${({disabled = false}) => disabled ? 'default' : 'pointer'};
`

export const ImportantBtnWrapper = styled.div<{ screenType: SCREEN_TYPES }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: ${({screenType}) => screenType === SCREEN_TYPES.DESKTOP ? 17 : 50}vw;
`;

export const BurgerWrapper = styled.div<{ isCross: boolean }>`
  position: relative;
  z-index: 5;

  width: 13.5%;
  height: 61%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  pointer-events: all;

  transition: all 0.3s ease-in-out;

  ${({isCross}) =>
    isCross &&
          css`
            & > ${BurgerLine}:first-child {
              transform: translate(0, 200%) rotate(45deg) skewX(-20deg);
            }

            & > ${BurgerLine}:last-child {
              transform: translate(0, -200%) rotate(-45deg) skewX(20deg);
            }
          `}
`;

export const BurgerWrapperUl = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;

  list-style-type: none;
`

export const DisableWrapper = styled.div`
  position: absolute;
  bottom: -0.8vw;
  left: 50%;
  z-index: 1;

  transform: translate(-50%, 80%);

  white-space: nowrap;

  background-color: #FFED21;

  padding: 0.3vw 0.6vw;
  box-sizing: border-box;
`;

export const BurgerLine = styled.div<{ isVisible?: boolean }>`
  width: 100%;
  height: 20%;

  opacity: ${({isVisible = true}) => (isVisible ? 1 : 0)};
  transform: skewX(-20deg) ${({isVisible = true}) => (isVisible ? 'scale(1)' : 'scale(0)')};

  background-color: #fff;

  transition: all 0.3s ease-in-out;
`;

export const BurgerMenu = styled.div<{ isOutAnimation: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;

  width: 100vw;
  height: 100vh;

  background: #141543;

  opacity: 0;
  animation: ${({isOutAnimation}) => (isOutAnimation ? slideOutRight : slideInRight)} 0.3s ease-in-out forwards;
`;

export const ChangeLanguageButton = styled.button`
  position: relative;
  top: 0;
  left: 0;

  white-space: nowrap;

  background: transparent;

  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
`;