import styled from 'styled-components';
import {SCREEN_TYPES} from '../../../hooks/useScreenSizeHook/useScreenSizeHook';

export const Title3D = styled.span<{ screenType: SCREEN_TYPES }>`
  color: #FFE150;
  font-size: ${({ screenType }) => (screenType === SCREEN_TYPES.DESKTOP ? '5vw' : '6vw')};
  transform: rotate(-2deg);

  text-shadow:
    0 0 10px rgba(255, 225, 80, 0.8),
    0 0 20px rgba(255, 225, 80, 0.5),
    1px 1px 0 #000000,
    2px 2px 0 #000000,
    3px 3px 0 #000000,
    4px 4px 0 #000000,
    5px 5px 0 #000000,
    6px 6px 0 #000000,
    7px 7px 0 #000000,
    8px 8px 0 #000000,
    9px 9px 0 #000000,
    10px 10px 12px #000000;
`;

export const Text3D = styled.span<{ screenType: SCREEN_TYPES }>`
  color: #FFFFFF;
  font-size: ${({ screenType }) => (screenType === SCREEN_TYPES.DESKTOP ? '2.8vw' : '5vw')};
  transform: rotate(-2deg);

  text-shadow:
    1px 1px 0 #000000,
    2px 2px 0 #000000,
    3px 3px 0 #000000,
    4px 4px 6px #000000;
`;