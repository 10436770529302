import {ReactNode, useState, useEffect} from 'react';

import {ModalContext} from './modalContext';

import {LOCAL_STORAGE_NAMES} from '../../localStorage/types';

import {LISTING_BFT_TARGET_TIME, WELCOME_PACK_TARGET_TIME} from '../../config';

type IModalProviderProps = {
  children: ReactNode
}

export const ModalProvider = ({children}: IModalProviderProps) => {
  const now = Date.now();

  const [listingModalVisible, setListingModalVisible] = useState<boolean>(false);
  const [isListingTimerFinished, setIsListingTimerFinished] = useState<boolean>(now > LISTING_BFT_TARGET_TIME);

  const [welcomeModalVisible, setWelcomeModalVisible] = useState<boolean>(false);
  const [isWelcomeTimerFinished, setIsWelcomeTimerFinished] = useState<boolean>(now > WELCOME_PACK_TARGET_TIME);

  useEffect(() => {
    const MODAL_DELAY = 7 * 1000; // 7 секунд
    const MODAL_RESET_TIME = 24 * 60 * 60 * 1000; // 24 часа
    const lastOpenTime = localStorage.getItem(LOCAL_STORAGE_NAMES.LAST_MODAL_OPEN_TIME);

    if (!lastOpenTime || now - Number(lastOpenTime) > MODAL_RESET_TIME) {
      localStorage.removeItem(LOCAL_STORAGE_NAMES.LAST_MODAL_OPEN_TIME);
      if (now < LISTING_BFT_TARGET_TIME) {
        const timer = setTimeout(() => {
          setListingModalVisible(true);
          localStorage.setItem(LOCAL_STORAGE_NAMES.LAST_MODAL_OPEN_TIME, String(now));
        }, MODAL_DELAY);
        return () => clearTimeout(timer);
      }
    }
  }, []);

  useEffect(() => {
    if (now > LISTING_BFT_TARGET_TIME) {
      const MODAL_DELAY = 3 * 1000; // 3 секунд

      if (now < WELCOME_PACK_TARGET_TIME) {
        const timer = setTimeout(() => setWelcomeModalVisible(true), MODAL_DELAY);
        return () => clearTimeout(timer);
      }
    }
  }, []);

  useEffect(() => {
    if (isListingTimerFinished) {
      setListingModalVisible(false);
    }
  }, [isListingTimerFinished]);

  useEffect(() => {
    if (isWelcomeTimerFinished) {
      setWelcomeModalVisible(false);
    }
  }, [isWelcomeTimerFinished]);

  return (
    <ModalContext.Provider
      value={{
        listingModalVisible, setListingModalVisible,
        isListingTimerFinished, setIsListingTimerFinished,
        welcomeModalVisible, setWelcomeModalVisible,
        isWelcomeTimerFinished, setIsWelcomeTimerFinished
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};