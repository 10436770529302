import {getAssetsPrefix} from '../../utils/getAssetsPrefix';

const ASSETS = {
  components: {
    header: {
      desktop: {
        BossFightersLogo: getAssetsPrefix('components/header/BossFightersLogo.webp'),
        HeaderBackground: getAssetsPrefix('components/header/HeaderBackground.webp'),
      },
      mobile: {
        HeaderBackground: getAssetsPrefix('components/header/mobile/HeaderBackground.webp'),
      },
    },
    footer: {
      desktop: {
        FooterBackground: getAssetsPrefix('components/footer/FooterBackground.webp'),
      },
      mobile: {
        FooterBackground: getAssetsPrefix('components/footer/mobile/FooterBackground.webp'),
      },
    },
    socialMedia: {
      desktop: {
        DiscordIcon: getAssetsPrefix('components/socialMedia/DiscordIcon.webp'),
        MediumIcon: getAssetsPrefix('components/socialMedia/MediumIcon.webp'),
        TelegramIcon: getAssetsPrefix('components/socialMedia/TelegramIcon.webp'),
        TiktokIcon: getAssetsPrefix('components/socialMedia/TiktokIcon.webp'),
        TwitterIcon: getAssetsPrefix('components/socialMedia/TwitterIcon.webp'),
        YoutubeIcon: getAssetsPrefix('components/socialMedia/YoutubeIcon.webp'),
      },
    },
    cardsSlider: {
      desktop: {
        Slide1: getAssetsPrefix('components/cardsSlider/Slide1.webp'),
        Slide2: getAssetsPrefix('components/cardsSlider/Slide2.webp'),
        Slide3: getAssetsPrefix('components/cardsSlider/Slide3.webp'),
        Slide4: getAssetsPrefix('components/cardsSlider/Slide4.webp'),
        SliderArrow: getAssetsPrefix('components/cardsSlider/SliderArrow.webp'),
      },
    },
    modal: {
      desktop: {
        ListingBFTBackground: getAssetsPrefix('components/modal/desktop/ListingBFTBackground.webp'),
        WelcomePackBackground: getAssetsPrefix('components/modal/desktop/WelcomePackBackground.webp'),
      },
      mobile: {
        ListingBFTBackgroundMob: getAssetsPrefix('components/modal/mobile/ListingBFTBackgroundMob.webp'),
        WelcomePackBackgroundMob: getAssetsPrefix('components/modal/mobile/WelcomePackBackgroundMob.webp'),
      }
    }
  },
  main: {
    teaserBlock: {
      desktop: {
        BossFighters: getAssetsPrefix('main/teaserBlock/BossFighters.webp'),
        Crown: getAssetsPrefix('main/teaserBlock/Crown.webp'),
        CrownOrange: getAssetsPrefix('main/teaserBlock/CrownOrange.webp'),
        MetaQuestIcon: getAssetsPrefix('main/teaserBlock/MetaQuestIcon.webp'),
        MetaQuest: getAssetsPrefix('main/teaserBlock/MetaQuest.webp'),
        EpicStore: getAssetsPrefix('main/teaserBlock/EpicStore.webp'),
        PlayDemoBtn: getAssetsPrefix('main/teaserBlock/PlayDemoBtn.webp'),
        SteamIcon: getAssetsPrefix('main/teaserBlock/SteamIcon.webp'),
        TeaserBlockBackground: getAssetsPrefix('main/teaserBlock/TeaserBlockBackground.webp'),
      },
      mobile: {
        TeaserBlockBackground: getAssetsPrefix('main/teaserBlock/mobile/TeaserBlockBackground.webp'),
      },
    },
    aboutBlock: {
      desktop: {
        MainBackground: getAssetsPrefix('main/aboutBlock/MainBackground.webp'),
        YoutubeButton: getAssetsPrefix('main/aboutBlock/YoutubeButton.webp'),
      },
      mobile: {
        MainBackground: getAssetsPrefix('main/aboutBlock/mobile/MainBackground.webp'),
      },
    },
    cardsBlock: {
      desktop: {
        ActiveBorderCard: getAssetsPrefix('main/cardsBlock/ActiveBorderCard.webp'),
        Arrow: getAssetsPrefix('main/cardsBlock/Arrow.webp'),
        ByOpenLoot: getAssetsPrefix('main/cardsBlock/ByOpenLoot.webp'),
        CardBackground: getAssetsPrefix('main/cardsBlock/CardBackground.webp'),
        EmailIcon: getAssetsPrefix('main/cardsBlock/EmailIcon.webp'),
        MainBackground: getAssetsPrefix('main/cardsBlock/MainBackground.webp'),
        SendIcon: getAssetsPrefix('main/cardsBlock/SendIcon.webp'),
      },
      mobile: {
        MainBackground: getAssetsPrefix('main/cardsBlock/mobile/MainBackground.webp'),
      },
    },
    mobileContactBlock: {
      ByOpenLoot: getAssetsPrefix('main/mobileContactBlock/ByOpenLoot.webp'),
      MainBackground: getAssetsPrefix('main/mobileContactBlock/MainBackground.webp'),
    }
  },
  bft: {
    rotationBFT: getAssetsPrefix('bft/RotationBFT.webm'),
    rotationBFTWebp: getAssetsPrefix('bft/rotationBFTWebp.webp'),
    useBFT: getAssetsPrefix('bft/UseBFT.webm'),
    useBFTWebp: getAssetsPrefix('bft/useBFTWebp.webp'),
    aboutBlock: {
      desktop: {
        Border: getAssetsPrefix('bft/aboutBlock/desktop/Border.webp'),
        AboutGraphicsBackground: getAssetsPrefix('bft/aboutBlock/desktop/AboutGraphicsBackground.webp'),
        SponsorMarks: getAssetsPrefix('bft/aboutBlock/desktop/SponsorMarks.webp'),
        Badge: getAssetsPrefix('bft/aboutBlock/desktop/Badge.webp'),
        Weapon: getAssetsPrefix('bft/aboutBlock/desktop/Weapon.webp'),
        Fighter: getAssetsPrefix('bft/aboutBlock/desktop/Fighter.webp'),
        Arrow1: getAssetsPrefix('bft/aboutBlock/desktop/Arrow1.webp'),
        Arrow2: getAssetsPrefix('bft/aboutBlock/desktop/Arrow2.webp'),
        Arrow3: getAssetsPrefix('bft/aboutBlock/desktop/Arrow3.webp'),
        Arrow4: getAssetsPrefix('bft/aboutBlock/desktop/Arrow4.webp'),
        Arrow5: getAssetsPrefix('bft/aboutBlock/desktop/Arrow5.webp'),
        Arrow6: getAssetsPrefix('bft/aboutBlock/desktop/Arrow6.webp'),
      },
      mobile: {
        BorderMob: getAssetsPrefix('bft/aboutBlock/mobile/BorderMob.webp'),
        AboutGraphicsBackgroundMob: getAssetsPrefix('bft/aboutBlock/mobile/AboutGraphicsBackgroundMob.webp'),
      }
    },
    useTokenBlock: {
      desktop: {
        UseTokenBlockBackground: getAssetsPrefix('bft/useTokenBlock/desktop/UseTokenBlockBackground.webp'),
        UseToken: getAssetsPrefix('bft/useTokenBlock/desktop/UseToken.webp'),
      },
    },
    tokenomicsBlock: {
      desktop: {
        TokenomicsGraphicsBackground: getAssetsPrefix('bft/tokenomicsBlock/desktop/TokenomicsGraphicsBackground.webp'),
        TokenomicsInfoBackground: getAssetsPrefix('bft/tokenomicsBlock/desktop/TokenomicsInfoBackground.webp'),
        TokenomicsGraphics: getAssetsPrefix('bft/tokenomicsBlock/desktop/TokenomicsGraphics.webp'),
      },
      mobile: {
        TokenomicsGraphicsBackgroundMob: getAssetsPrefix('bft/tokenomicsBlock/mobile/TokenomicsGraphicsBackgroundMob.webp'),
      }
    },
    roadmapBlock: {
      desktop: {
        RoadmapBlockBackground: getAssetsPrefix('bft/roadmapBlock/desktop/RoadmapBlockBackground.webp'),
        RoadmapLightning: getAssetsPrefix('bft/roadmapBlock/desktop/RoadmapLightning.webp'),
      },
      mobile: {
        RoadmapBlockBackgroundMob: getAssetsPrefix('bft/roadmapBlock/mobile/RoadmapBlockBackgroundMob.webp'),
      }
    },
    faqBlock: {
      desktop: {
        FAQBlockBackground: getAssetsPrefix('bft/faqBlock/desktop/FAQBlockBackground.webp'),
        Crown: getAssetsPrefix('bft/faqBlock/desktop/Crown.webp'),
      },
    }
  }
};

export default ASSETS