import React from 'react';

const Arrow = (props: any) => (
  <svg width="34" height="46" viewBox="0 0 34 46" fill="current" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.6226 12.4327L0.203125 24.68L0.203124 12.4327L16.6226 0.319993L33.4458 12.4327L33.4458 24.68L16.6226 12.4327Z"
      fill="current"/>
    <path
      d="M16.6226 33.4328L0.203125 45.6801L0.203124 33.4328L16.6226 21.3201L33.4458 33.4328L33.4458 45.6801L16.6226 33.4328Z"
      fill="current"/>
  </svg>

);

export default Arrow;
